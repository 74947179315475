import { safeJSONParse } from "@local/utils/src/safeJSONParse"
import { RecoverResponse } from "./useIP"

export const getRecoveryStorage = () => {
  const raw =
    localStorage && localStorage.hasOwnProperty("getItem")
      ? localStorage.getItem("_r2")
      : null

  const data = !raw ? null : safeJSONParse<RecoverResponse>(raw)
  const bot = data?.bot
  const ip = data?.ip
  const isp = data?.isp
  const loc = data?.loc
  const geo = data?.geo
  const proxy = data?.proxy
  const ua = data?.ua
  const weather = data?.w

  return {
    data,
    bot,
    ip,
    isp,
    loc,
    geo,
    proxy,
    ua,
    weather,
  }
}
